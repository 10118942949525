import { axios } from 'ats-recruiter/src/api/RequestClient';
import { IGetMarketingMetadataV1Response } from '@interface/marketing/getMarketingMetadataV1';
import { IGetMarketingAnalyticsV1Response } from '@interface/marketing/getMarketingAnalyticsV1';
import { IGetMarketingPluginV1Response } from '@interface/marketing/getMarketingPluginV1';

/** 채용사이트 메타태그 정보 조회 */
export const getMarketingMetadataV1 = async (
  prefix: string,
  pathname?: string
): Promise<IGetMarketingMetadataV1Response> => {
  try {
    const { data } = await axios.get('/marketing/v1/meta-data', { headers: { prefix } });
    return data;
  } catch (e) {
    console.error('getMarketingMetadataV1 = prefix : ', prefix, 'error : ', e, 'pathname: ', pathname);
    throw e;
  }
};

/** 채용사이트 메타태그 정보 조회 */
export const getMarketingAnalyticsV1 = async (prefix: string): Promise<IGetMarketingAnalyticsV1Response> => {
  try {
    const { data } = await axios.get('/marketing/v1/analytics', { headers: { prefix } });
    return data;
  } catch (e) {
    console.error('getMarketingAnalyticsV1= prefix : ', prefix, 'error : ', e);
    return { googleAnalyticsId: '' };
  }
};

/** JF 채용사이트 채용공고 상세 메타태크 정보 조회 */
export const getJFMarketingMetadataV1 = async ({
  positionSn,
  prefix,
}: {
  positionSn: number;
  prefix: string;
}): Promise<IGetMarketingMetadataV1Response> => {
  try {
    const { data } = await axios.get(`/marketing/v1/position/${positionSn}/meta-data`, { headers: { prefix } });
    return data;
  } catch (e) {
    console.error('getJFMarketingMetadataV1= prefix : ', prefix, 'error : ', e);
    throw e;
  }
};

/** 플러그인 조회 */
export const getMarketingPluginV1 = async (prefix: string): Promise<IGetMarketingPluginV1Response> => {
  try {
    const { data } = await axios.get('/marketing/v1/plugin', { headers: { prefix } });
    return data;
  } catch (e) {
    console.error(e);
    return { channelTalk: { pluginKey: '' } };
  }
};
